/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

import Header from "./Header"
import Seo from "./Seo";
import GlobalStyles from "../styles/Global";
import { Footer, Main, SmallP } from "../styles/Global.styled";

const Layout = ({children, title}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const pageTitle = title || data.site.siteMetadata?.title

    return (
        <>
            <GlobalStyles/>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
            <Seo title={pageTitle}/>
            <Main>
                {children}
            </Main>
            <Footer>
                <SmallP>
                    © {new Date().getFullYear()}, Artifonica
                </SmallP>
            </Footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
