import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
// import {GatsbyImage, StaticImage} from "gatsby-plugin-image"

export const SmallP = styled.p`
  font-size: x-small;
`

export const Footer = styled.main`
  position: relative;
  top: 0.5rem;
`

export const Main = styled.main`
  position: relative;
  top: 1rem;
`

export const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 3rem 0.75rem;
  margin: 1rem 0.5rem;
  //animation: slideInFromBottom 1s ease-in;
`

export const CardBox = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  border: 0.08rem solid var(--primary-color);
  border-radius: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
  //display: grid;
  //grid-template-columns: 1.5fr 1fr;
  //grid-template-rows: 1.5fr 2fr;
  //grid-template-areas: "head pic / text text";
  //position: absolute;
  background: var(--tertiary-color);
  transition: border 0.15s ease-in-out;
  img {
    border-radius: 0.4rem;
    //grid-area: pic;
  }
  :hover {
    border: 0.12rem solid black;
  }
  //a:hover {
  //  background-color: var(--primary-color-transparent);
  //}
`

export const CardDetail = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-template-rows: minmax(2rem, 4rem) minmax(4rem, auto) 1fr;
  //grid-template-rows: 8rem 16rem 1fr;
  grid-gap: 0.25rem;
  grid-template-areas: 
          "title pic"
          "subtitle pic"
          "text text";
  //position: absolute;
  //border: 0.08rem solid var(--primary-color);
  //border-radius: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
  //background: var(--tertiary-color);
  //transition: border 0.15s ease-in-out;
  //:hover {
  //  border: 0.12rem solid black;
  //}
  //a:hover {
  //  background-color: var(--primary-color-transparent);
  //}
`

export const CardImage = styled(GatsbyImage)`
  grid-area: pic;
  max-width: 1fr;
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  aspect-ratio: 1;
  overflow: hidden;
`

export const CardTitle = styled.h1`
  grid-area: title;
`

export const CardSubTitle = styled.div`
  grid-area: subtitle;
`

export const CardBody = styled.div`
  grid-area: text;
`

export const GenreList = styled.ul`
  font-size: small;
  margin: 0;
  padding: 0;
`

export const Genre = styled.li`
  display: inline-block;
  border: 1px solid darkgray;
  padding: 0.2rem 1rem;
  margin: 0 1rem;
  list-style: none;
  font-size: xx-small;
  background: lightgrey;
`
