import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;100;500&display=swap');

  :root {
    // synwave theme
    --primary-color: #4CC9F0;
    --primary-color-transparent: #4CC9F030;
    --secondary-color: #4361EE;
    --secondary-color-transparent: #4361EE30;
    --tertiary-color: #F9DB6D;
    --tertiary-color-transparent: #F9DB6D40;
  }
  
  
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    position:relative;
    top:2rem;
    //right:0;
    //bottom:0;
    //left:0;  
    height: 100vh;
    background-image: linear-gradient(to top right, var(--primary-color), var(--tertiary-color));
    background-repeat: no-repeat;
    background-attachment: fixed;
    max-width: 960px;
    margin: 0 auto;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    border-bottom: #280838 2px;
  }
  main {
    margin: .5rem;
  }
`

export default GlobalStyles
