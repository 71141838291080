import * as React from "react"
// import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "Artifonica Logo 3" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, width: 50, formats: AUTO)
        }
        name
      }
    }
  `)

  return (
    <HeaderBar>
      <div>
        <h1 style={{ margin: 0 }}>
          <Link to="/">
            <Logo image={getImage(data.file.childImageSharp)} alt="logo"/>
            {siteTitle}
          </Link>
        </h1>
      </div>
    </HeaderBar>
  )
}

const Logo = styled(GatsbyImage)`
  margin-right: 0.5rem;
`

const HeaderBar = styled.header`
  background: var(--tertiary-color);
  //width: 100vw;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  //bottom: --var(100vh - 2rem);
  padding: 0.5rem 2rem;
`

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }
//
// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
